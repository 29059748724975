<template>
    <div id="tags-view-container" class="tags-view-container">
      <draggable v-model="visitedViews" group="tag_group" @start="drag = true" @end="drag = false" item-key="1">
        <template #item="{element}">
          <a-tag :color="getTheme(element)" :closable="!isAffix(element)" @close="closeSelectedTag(element)" @click="routeToPath(element, index)" class="tag-item">
            <svg-icon :icon="element.meta.icon" class="anticon" /> {{element.meta.title}}
          </a-tag>
        </template>
      </draggable>
    </div>

    <a-dropdown class="tags-view-handle">
        <a-button @mouseover="smileOver" @mouseout="smileOut">
            <template #icon><SmileTwoTone :rotate="smileRotate"/></template>
        </a-button>
        <template #overlay>
            <a-menu>
                <a-menu-item @click="closeOther">
                    <CloseOutlined /><span>关闭其他</span>
                </a-menu-item>
                <a-menu-item @click="closeLeft">
                    <ArrowLeftOutlined /><span>关闭左侧</span>
                </a-menu-item>
                <a-menu-item @click="closeRight">
                    <ArrowRightOutlined /><span>关闭右侧</span>
                </a-menu-item>
                <a-menu-item @click="closeAll">
                    <CloseOutlined /><span>关闭全部</span>
                </a-menu-item>
            </a-menu>
        </template>
    </a-dropdown>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import {ITagView, TagViewModule} from "@/store/module/tag-views"
import {Watch} from "vue-property-decorator"
import {RouteRecordRaw} from "vue-router"
import path from "path"
import {PermissionModule} from "@/store/module/permissions"
import { DownloadOutlined, SmileTwoTone, ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined } from "@ant-design/icons-vue";
import Draggable from 'vuedraggable'

@Options({
    name: "tag-view",
    components: {
        DownloadOutlined,
        SmileTwoTone,
        ArrowLeftOutlined,
        ArrowRightOutlined,
        CloseOutlined,
        Draggable
    }
})
export default class TagView extends Vue {

    private tagThemes = ['pink', 'red', 'yellow', 'orange', 'cyan', 'green', 'blue', 'purple', 'geekblue', 'magenta', 'volcano', 'gold', 'lime']
    private activeTheme = '#2db7f5'
    private affixTags: ITagView[] = []
    private selectedTag: ITagView = {index: 0}
    public smileRotate = 0
    public overInterval: any
    public outInterval: any

    get visitedViews() {
        return TagViewModule.visitedViews
    }

    set visitedViews(visitedViews: ITagView[]) {
        TagViewModule.setVisitedViews(visitedViews)
    }

    get routes() {
        return PermissionModule.routes
    }

    mounted() {
        this.initTagsView()
    }

    public closeOther() {
        TagViewModule.delOthersViews(this.selectedTag)
    }

    public closeLeft() {
        TagViewModule.delLeftVisitedViews(this.$route.path)
    }

    public closeRight() {
        TagViewModule.delRightVisitedViews(this.$route.path)
    }
    public closeAll() {
        TagViewModule.delAllViews()
    }
    public smileOver() {
      if (this.outInterval) {
        clearInterval(this.outInterval)
      }
      this.overInterval = setInterval(() => {
          if (this.smileRotate < 180) {
              this.smileRotate += 10
          } else {
              clearInterval(this.overInterval)
          }
      }, 15)
    }

    public smileOut() {
      if (this.overInterval) {
        clearInterval(this.overInterval)
      }
      this.outInterval = setInterval(() => {
          if (this.smileRotate > 0) {
              this.smileRotate -= 10
          } else {
              clearInterval(this.outInterval)
          }
      }, 15)
    }

    private getTheme(tag: ITagView): string {
        const number = Math.floor((Math.random() * 100)) % 13
        return this.isActive(tag) ? this.activeTheme : this.tagThemes[number]
    }

    private isActive(route: ITagView) {
        return route.path === this.$route.path
    }

    private isAffix(tag: ITagView) {
        return tag.meta && tag.meta.affix
    }

    private closeSelectedTag(view: ITagView) {
        TagViewModule.delView(view)
        if (this.isActive(view)) {
            this.toLastView(TagViewModule.visitedViews, view)
        }
    }

    private routeToPath(tag: ITagView, index: number) {
        this.$router.push(tag.path)
    }

    private toLastView(visitedViews: ITagView[], view: ITagView) {
        const latestView = visitedViews.slice(-1)[0]
        if (latestView !== undefined && latestView.fullPath !== undefined) {
            this.$router.push(latestView.fullPath)
        } else {
            if (view.name === 'Dashboard') {
                this.$router.replace({ path: '/redirect' + view.fullPath })
            } else {
                this.$router.push('/')
            }
        }
    }

    private moveToCurrentTag() {
        const tags = this.$refs.tag as any[]
        this.$nextTick(() => {
            for (const tag of tags) {
                if((tag.to as ITagView).path === this.$route.path) {
                }
            }
        })
    }

    private initTagsView() {
        this.affixTags = this.filterAffixTags(this.routes)
        for (const tag of this.affixTags) {
            if (tag.name) {
                TagViewModule.addVisitedView(tag)
            }
        }
    }

    private filterAffixTags(routes: RouteRecordRaw[], basePath = '/') {
        let tags: ITagView[] = []
        routes.forEach(route => {
            if (route.meta && route.meta.affix) {
                const tagPath = path.resolve(basePath, route.path)
                tags.push({
                    fullPath: tagPath,
                    path: tagPath,
                    name: route.name,
                    meta: {...route.meta}
                } as any)
            }
            if (route.children) {
                const childTags = this.filterAffixTags(route.children, route.path)
                if (childTags.length >= 1) {
                    tags = [...tags, ...childTags]
                }
            }
        })
        return tags
    }

    private addTags() {
        const { name } = this.$route
        if (name) {
            this.selectedTag = {index: 0, ...this.$route}
            TagViewModule.addView(this.selectedTag)
        }
        return false
    }

    @Watch('$route')
    private onRouteChange() {
        this.addTags()
    }
}
</script>

<style lang="scss" scoped>
.tags-view-container {
    height: 34px;
    width: calc(100% - 40px);
    background: #fff;
    border-bottom: 1px solid #d8dce5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    float: left;
    white-space: nowrap;
    overflow-x: hidden;

    .tag-item {
        cursor: pointer;
    }

    .tags-view-wrapper {
        .tags-view-item {
            display: inline-block;
            position: relative;
            cursor: pointer;
            height: 26px;
            line-height: 26px;
            border: 1px solid #d8dce5;
            color: #495060;
            background: #fff;
            padding: 0 8px;
            font-size: 12px;
            margin-left: 5px;
            margin-top: 4px;

            &:first-of-type {
                margin-left: 15px;
            }

            &:last-of-type {
                margin-right: 15px;
            }
        }
    }
}
.tags-view-handle {
    margin-left: 5px;
    margin-top: 2px;
    z-index: 200;
}
</style>
