// @ts-ignore
import { DirectiveBinding, VNode, Directive } from "vue";
import { copy } from "iclipboard/esm";
import {message} from "ant-design-vue";

export const clip: Directive = {
    mounted(el: HTMLElement, binding: DirectiveBinding, vNode: VNode) {
        el.style.cssText = 'cursor: pointer;'
        el.addEventListener('click', () => {
            if (copy(binding.value)) {
                message.success(binding.value.length > 50 ? '已复制!' : binding.value)
            } else {
                message.error('复制失败!')
            }
        })
    }
}