import requests from '@/utils/request'

export const doLogin = (params: any) =>
    requests({
        url: '/aliImg/login',
        method: 'get',
        params: params
    })

export const getSystemInfo = () =>
    requests({
        url: '/system/info',
        method: 'get'
    })

export const getAppList = (params: any) =>
    requests({
        url: '/api/app/list',
        method: 'get',
        params
    })

/** 获取授权码 */
export const getAuthorizationCode = (params: any) =>
    requests({
        url: '/api/auth/code',
        method: 'get',
        params
    })

/** 应用上架 / 下架 */
export const publish = (appId: string) =>
    requests({
        url: '/api/app/' + appId,
        method: 'put'
})

/** 应用分页 */
export const appPage = (params: any) =>
    requests({
        url: '/api/app/page',
        method: 'get',
        params
    })

/** 编辑应用 */
export const editApp = (params: any) =>
    requests({
        url: '/api/app/edit',
        method: 'post',
        data: params
    })

/** 删除应用 */
export const removeApp = (appId: any) =>
    requests({
        url: '/api/app/' + appId,
        method: 'delete'
    })

/** 添加应用 */
export const addApp = (newApp: any) =>
    requests({
        url: '/api/app/add',
        method: 'post',
        data: newApp
    })

/** 全部应用 */
export const allApp = (params: any) =>
    requests({
        url: '/api/app/all',
        method: 'get',
        params
    })

/** 应用分配 */
export  const allotApp = (params: any) =>
    requests({
        url: '/api/app/allot',
        method: 'post',
        data: params
    })
