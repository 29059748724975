// @ts-ignore
import { DirectiveBinding, VNode, ObjectDirective } from "vue";
import { SettingsModule} from "@/store/module/settings";

export const down: ObjectDirective = {
    mounted(el: HTMLElement, binding: DirectiveBinding, vNode: VNode) {
        el.style.cssText = 'cursor: pointer;'
        el.addEventListener('click', () => {
            let link = document.createElement('a')
            let url = SettingsModule.fileApi + binding.value
            fetch(url).then(res => res.blob()).then(blob => {
                link.href = URL.createObjectURL(blob)
                link.download = ''
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url)
            })
        })
    }
}
