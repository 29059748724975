import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-106c86ed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_tag_view = _resolveComponent("tag-view")!
  const _component_app_main = _resolveComponent("app-main")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["app-wrapper", _ctx.sideBarClass])
    }, [
      _createVNode(_component_sidebar, {
        class: _normalizeClass(["sidebar-container", _ctx.themeBack])
      }, null, 8 /* PROPS */, ["class"]),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.navBar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_nav_bar)
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.tagView)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_tag_view)
            ]))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_app_main)
      ])
    ], 2 /* CLASS */),
    _createCommentVNode("  <live2d class=\"live\" :style=\"style\" :model=\"[1, 53]\" :direction=\"direction\" :size=\"size\"></live2d>")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}