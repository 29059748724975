import router from './router'
import {RouteLocationNormalized, RouteRecordRaw, NavigationGuardNext, RouteMeta} from "vue-router"
import NProgress from 'nprogress'
import { UserModule } from '@/store/module/user'
import { PermissionModule } from '@/store/module/permissions'
import {message, Modal} from 'ant-design-vue'
import { SettingsModule} from "@/store/module/settings"

NProgress.configure({ showSpinner: false })
const whiteList = ['/login', '/authorize-redirect', '/test']

const getPageTitle = (key: string) => {
  /*if (key) {
      return key
  }*/
  return `${SettingsModule.title}`
}
router.beforeEach(async(to: RouteLocationNormalized,  from: RouteLocationNormalized, next: NavigationGuardNext) => {
    NProgress.start()
    Modal.destroyAll();
    if (UserModule.access_token) {
        if (to.path === '/login') {
            next({ path : '/'})
            NProgress.done()
        } else {
            try {
                await UserModule.GetUserInfo()
                PermissionModule.GenerateRoutes();
                PermissionModule.dynamicRoutes.forEach(route => {
                    router.addRoute(route)
                })
                next()
            } catch (error) {
                UserModule.ResetToken()
                message.error('登录已过期, 请重新登录')
                next(`/login?redirect=${to.path}`)
                NProgress.done()
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            UserModule.LogOut()
            next(`/login?redirect=${to.path}`)
            NProgress.done()
        }
    }
});

router.afterEach((to: RouteLocationNormalized) => {
    NProgress.done()
    SettingsModule.globalUnLoading()
    document.title = getPageTitle(to.meta.title as string);
});
