import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c1e3f0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, {
    size: "large",
    spinning: _ctx.spinning
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_KeepAlive, { include: _ctx.cachedViews }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ], 1032 /* PROPS, DYNAMIC_SLOTS */, ["include"]))
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["spinning"]))
}