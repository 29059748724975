import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators"
import store from '@/store'

export interface ISettings {
    menuCollapsed: boolean;
    title: string;
    tagView: boolean;
    navbar: boolean;
    light: boolean;
    fileApi: string;
    api: string;
    loading: boolean;
}

@Module({ dynamic: true, name: 'settings', store})
class Settings extends VuexModule implements ISettings {

    public menuCollapsed = false
    public title = "SSO"
    public tagView = true
    public navbar = true
    public light = false
    public loading = false
    public fileApi = process.env.VUE_APP_FILE_API + "/"
    public api = process.env.VUE_APP_BASE_API + "/"

    @Mutation
    private CHANGE_MENU_COLLAPSED() {
        this.menuCollapsed = !this.menuCollapsed
    }

    @Mutation
    private CHANGE_MENU_LIGIT() {
        this.light = !this.light
    }

    @Mutation
    private GLOBAL_LOADING() {
        this.loading = true
    }

    @Mutation
    private GLOBAL_UNLOADING() {
        this.loading = false
    }

    @Action
    public changeMenuCollapsed() {
        this.CHANGE_MENU_COLLAPSED()
    }

    @Action
    public changeMenuLight() {
        this.CHANGE_MENU_LIGIT()
    }

    @Action
    public globalLoading() {
        this.GLOBAL_LOADING()
    }

    @Action
    public globalUnLoading() {
        this.GLOBAL_UNLOADING()
    }


}

export const SettingsModule = getModule(Settings)

