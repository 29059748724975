<template>
  <div>
    <a-menu mode="inline" :inline-collapsed="collapsed" :theme="theme" v-model:selectedKeys="selectKeys" v-model:openKeys="openKeys">
      <a-menu-item key="/dashboard" @click="toDashboard">
        <a-row :class="light ? 'top-content' : 'top-content-black'">
            <span v-if="!collapsed">
                Dashboard
            </span>
            <span class="anticon" v-else>
                <svg-icon icon="dashboard" />
            </span>
        </a-row>
      </a-menu-item>
      <sidebar-item v-for="route in routes" :key="route.path" :item="route"></sidebar-item>
    </a-menu>
  </div>
</template>

<script lang="ts">
import { SettingsModule } from "@/store/module/settings"
import { PermissionModule } from "@/store/module/permissions"
import SideBarItem from "@/components/sidebar/SideBarItem.vue"
import {RouteRecordRaw, useRoute, useRouter} from "vue-router";
import { ref, computed } from 'vue'
import {Options, Vue} from "vue-class-component";
import {Watch} from "vue-property-decorator";

@Options({
    components: {
        "sidebar-item": SideBarItem
    },
    name: 'sideBar'
})
export default class SideBar extends Vue {

    public menuWidth = ref('200px')
    public selectKeys: string[] = []
    public openKeys: string[] = []

    get collapsed() {
        return SettingsModule.menuCollapsed;
    }

    get routes() {
        return PermissionModule.routes;
    }

    get theme() {
        return SettingsModule.light ? 'light' : 'dark';
    }

    get light() {
        return SettingsModule.light
    }

    public toDashboard() {
        this.$router.push("/")
    }

    @Watch("$route")
    public routeChange(route: RouteRecordRaw) {
        const matched: any[] = (route as any).matched
        matched[0].children.length > 1
        ? (this.selectKeys = [route.path])
        : (this.selectKeys = [matched[0].path])
        this.openKeys = [matched[0].path]
    }

}
</script>

<style scoped>
.top-content {
  font-size: 20px;
  background-color: aliceblue;
}
.top-content-black {
  font-size: 20px;
}
</style>
