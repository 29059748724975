import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_list_item_meta = _resolveComponent("a-list-item-meta")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_bell_outlined = _resolveComponent("bell-outlined")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_popover = _resolveComponent("a-popover")!

  return (_openBlock(), _createBlock(_component_a_popover, {
    trigger: "click",
    placement: "bottomRight",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event))
  }, {
    content: _withCtx(() => [
      _createVNode(_component_a_tabs, null, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: _ctx.tabs[0],
            tab: _ctx.tabs[0]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_list_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_list_item_meta, {
                        title: "你收到了 14 份新周报",
                        description: "一年前"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_avatar, {
                            style: {"background-color":"white"},
                            slot: "avatar",
                            src: "https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png"
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_a_list_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_list_item_meta, {
                        title: "你推荐的 曲妮妮 已通过第三轮面试",
                        description: "一年前"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_avatar, {
                            style: {"background-color":"white"},
                            slot: "avatar",
                            src: "https://gw.alipayobjects.com/zos/rmsportal/OKJXDXrmkNshAMvwtvhu.png"
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_a_list_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_list_item_meta, {
                        title: "这种模板可以区分多种通知类型",
                        description: "一年前"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_avatar, {
                            style: {"background-color":"white"},
                            slot: "avatar",
                            src: "https://gw.alipayobjects.com/zos/rmsportal/kISTdvpyTAhtGxpovNWd.png"
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["tab"])),
          (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: _ctx.tabs[1],
            tab: _ctx.tabs[1]
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 消息 ")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["tab"])),
          (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: _ctx.tabs[2],
            tab: _ctx.tabs[2]
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 待办 ")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["tab"]))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_badge, {
        dot: _ctx.badgeShow,
        color: "cyan"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_bell_outlined, { class: "navbar-message" })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dot"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}