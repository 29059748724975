import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators"
import store from "@/store"
import {RouteRecordRaw} from "vue-router"

export interface ITagView extends Partial<any>{
    title?: string,
    index: number
}

export interface ITagsViewState {
    visitedViews: ITagView[]
    cachedViews: (string | undefined)[]
}


@Module({dynamic: true, name: "tagViews", store})
class TagViews extends VuexModule implements ITagsViewState {

    public visitedViews: ITagView[] = []
    public cachedViews: (string | undefined)[] = []

    @Mutation
    private ADD_VISITED_VIEW(view: ITagView) {
        if (this.visitedViews.some(v => v.path === view.path)) return;
        this.visitedViews.push(
            Object.assign({}, view, {
                title: view.meta.title || 'no-name',
                index: this.visitedViews.length
            })
        )
    }

    @Mutation
    private DEL_LEFT_VISITED_VIEWS(path: string) {
        if (!this.visitedViews.some(v => v.path === path))
            return
        const index = this.visitedViews.filter(v => v.path === path)[0].index
        this.visitedViews = this.visitedViews.filter(v => v.meta.affix || v.index >= index)
        for (let i = 0; i < this.visitedViews.length; i++) {
            this.visitedViews[i].index = i
        }
    }

    @Mutation
    private DEL_RIGHT_VISITED_VIEWS(path: string) {
        if (!this.visitedViews.some(v => v.path === path))
            return
        const index = this.visitedViews.filter(v => v.path === path)[0].index
        this.visitedViews = this.visitedViews.filter(v => v.meta.affix || v.index <= index)
        for (let i = 0; i < this.visitedViews.length; i++) {
            this.visitedViews[i].index = i
        }
    }

    @Mutation
    private ADD_CACHED_VIEW(view: ITagView) {
        if (view.name === null) return;
        if (this.cachedViews.includes(view.name)) return;
        if (!view.meta.noCache) {
            this.cachedViews.push(view.name)
        }
    }

    @Mutation
    private DEL_VISITED_VIEW(view: ITagView) {
        for (const [i, v] of this.visitedViews.entries()) {
            if (v.path === view.path) {
                this.visitedViews.splice(i, 1)
                break
            }
        }
    }

    @Mutation
    private DEL_CACHED_VIEW(view: ITagView) {
        if (view.name === null) return
        const index = this.cachedViews.indexOf(view.name)
        index > -1 && this.cachedViews.splice(index, 1)
    }

    @Mutation
    private DEL_OTHERS_VISITED_VIEWS(view: ITagView) {
        this.visitedViews = this.visitedViews.filter(v => {
            return v.meta.affix || v.path === view.path
        })
    }

    @Mutation
    private DEL_OTHERS_CACHED_VIEWS(view: ITagView) {
        if (view.name === null) return
        const index = this.cachedViews.indexOf(view.name)
        if (index > -1) {
            this.cachedViews = this.cachedViews.slice(index, index + 1)
        } else {
            // if index = -1, there is no cached tags
            this.cachedViews = []
        }
    }

    @Mutation
    private DEL_ALL_VISITED_VIEWS() {
        const affixTags = this.visitedViews.filter(tag => tag.meta.affix)
        this.visitedViews = affixTags
    }

    @Mutation
    private DEL_ALL_CACHED_VIEWS() {
        this.cachedViews = []
    }

    @Mutation
    private UPDATE_VISITED_VIEW(view: ITagView) {
        for (let v of this.visitedViews) {
            v = Object.assign(v, view)
            break
        }
    }

    @Mutation
    private SET_VISITED_VIEWS(visitedViews: ITagView[]) {
        this.visitedViews = visitedViews;
        for (let i = 0; i < this.visitedViews.length; i++) {
            this.visitedViews[i].index = i
        }
    }

    @Action
    public addView(view: ITagView) {
        this.ADD_VISITED_VIEW(view)
        this.ADD_CACHED_VIEW(view)
    }

    @Action
    public addVisitedView(view: ITagView) {
        this.ADD_VISITED_VIEW(view)
    }

    @Action
    public delVisitedView(view: ITagView) {
        this.DEL_VISITED_VIEW(view)
    }

    @Action
    public delView(view: ITagView) {
        this.DEL_VISITED_VIEW(view)
        this.DEL_CACHED_VIEW(view)
    }

    @Action
    public delCachedView(view: ITagView) {
        this.DEL_CACHED_VIEW(view)
    }

    @Action
    public delOthersViews(view: ITagView) {
        this.DEL_OTHERS_VISITED_VIEWS(view)
        this.DEL_OTHERS_CACHED_VIEWS(view)
    }

    @Action
    public delAllViews() {
        this.DEL_ALL_VISITED_VIEWS()
        this.DEL_ALL_CACHED_VIEWS()
    }

    @Action
    public delAllCachedViews() {
        this.DEL_ALL_CACHED_VIEWS()
    }

    @Action
    public updateVisitedView(view: ITagView) {
        this.UPDATE_VISITED_VIEW(view)
    }

    @Action
    public delLeftVisitedViews(path: string) {
        this.DEL_LEFT_VISITED_VIEWS(path)
    }

    @Action
    public delRightVisitedViews(path: string) {
        this.DEL_RIGHT_VISITED_VIEWS(path)
    }

    @Action
    public setVisitedViews(visitedViews: ITagView[]) {
        this.SET_VISITED_VIEWS(visitedViews);
    }
}

export const TagViewModule = getModule(TagViews)
