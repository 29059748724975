import md5 from 'js-md5'

export function sortJson(json: any, isGet: boolean): Object {
    let arr: any[] = []
    let num: number = 0
    for (let key in json) {
        arr[num] = key
        num++
    }
    arr.sort()
    let sortObj: any = {}
    arr.forEach((key: any) => {
        let val: any = json[key]
        if (val !== undefined && val != null) {
            if (val instanceof Array) {
                for (let item in val) {
                    if (val[item] instanceof Object) {
                        val[item] = sortJson(val[item], isGet)
                    }
                }
                sortObj[key] = val.sort()
            } else if (val instanceof Object) {
                sortObj[key] = sortJson(val, isGet)
            } else {
                isGet ? sortObj[key] = val.toString() : sortObj[key] = val
            }
        }
    })

    return sortObj
}

export function getSign(url: string, requestParams: Object, isGet: boolean) {
    let urlParams = parseQueryString(url);
    let jsonObj = mergeObject(urlParams, requestParams);

    const sortBodys = sortJson(jsonObj, isGet)
    const bodys = JSON.stringify(sortBodys)
    return md5(bodys).toUpperCase()
}

export function parseQueryString(url: string): Object {
    let urlReg = /^[^\?]+\?([\w\W]+)$/,
        paramReg = /([^&=]+)=([\w\W]*?)(&|$|#)/g,
        urlArray = urlReg.exec(url);
    const result = {};
    if (urlArray && urlArray[1]) {
        let paramString = urlArray[1], paramResult;
        while ((paramResult = paramReg.exec(paramString)) != null) {
            if (paramResult[1] != 'sign') {
                result[paramResult[1]] = paramResult[2];
            }
        }
    }
    return result;
}

export function mergeObject(objectOne: Object, objectTwo: Object) {
    if (Object.keys(objectTwo).length > 0) {
        for (let key in objectTwo) {
            objectOne[key] = objectTwo[key];
        }
    }
    return objectOne;
}

export function urlEncode(param: any, key: any, encode: any) {
    if (param == null) return '';
    let paramStr = '';
    let t = typeof (param);
    if (t == 'string' || t == 'number' || t == 'boolean') {
        paramStr += '&' + key + '=' + ((encode == null || encode) ? encodeURIComponent(param) : param);
    } else {
        for (let i in param) {
            let k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
            paramStr += urlEncode(param[i], k, encode);
        }
    }
    return paramStr;
};