import {createApp, ObjectDirective} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd, { message, Form, notification } from 'ant-design-vue'
import 'nprogress/nprogress.css'
import axios from "axios"
import SvgIcon from "@/icon/SvgIcon.vue"
import '@/icon'
import '@/permission'
import * as directives from '@/directive'
import SmartVerify from '@/components/smart_verify/index.vue'
import VueSnap from 'vue-snap'

const app = createApp(App)

app.use(store).use(router).use(Antd).use(VueSnap).component('svg-icon', SvgIcon).mount('#app')
app.config.globalProperties.$message = message
app.config.globalProperties.$form = Form
app.config.globalProperties.$notification = notification


Object.keys(directives).forEach(key => {
    app.directive(key, (directives as { [key: string]: ObjectDirective })[key])
})
