<template>
  <template v-if="item.meta && !item.meta?.hidden">
    <template v-if="!hasChild()">
      <!--<sidebar-item-link :to="item.path">-->
      <a-menu-item :key="item.path" @click.capture="handleLink">
        <span class="anticon"><svg-icon :icon="item.meta.icon" /></span>
        <span> {{item.meta.title}}</span>
      </a-menu-item>
      <!--</sidebar-item-link>-->
    </template>
    <a-sub-menu v-else :key="item.path">
      <template v-slot:title>
        <span class="anticon"><svg-icon :icon="item.meta.icon" /></span>
        <span>{{item.meta.title}}</span>
      </template>
      <template v-if="item.children">
        <sidebar-item v-for="child in item.children" :key="child.path" :item="child"></sidebar-item>
      </template>
    </a-sub-menu>
  </template>
</template>

<script lang="ts">
import SideBarItemLink from "@/components/sidebar/SideBarItemLink.vue"
import { UserOutlined, SafetyOutlined,InfoCircleOutlined, AppstoreTwoTone, AppstoreOutlined } from "@ant-design/icons-vue"
import {computed, createVNode} from 'vue'
import {SettingsModule} from "@/store/module/settings";
import { isExternal} from "@/utils/validate"
import {useRoute, useRouter} from "vue-router";

export default {

  name: "sidebar-item",
  components: {
    "sidebar-item-link": SideBarItemLink,
    UserOutlined,
    AppstoreTwoTone,
    AppstoreOutlined
  },

  props: {
    item: Object
  },

  setup(props, context) {

    const router = useRouter()
    const route = useRoute()

    let hasChild = (): Boolean => {
      if (props.item.meta?.root) {
        return false;
      }
      if (props.item.meta?.hidden) {
        return false;
      }
      if (props.item.children) {
        return props.item.children.length > 0
      }
      return false
    }
    const collapsed = computed({
      get: () => {
        return SettingsModule.menuCollapsed;
      },
      set: (val) => {

      }
    })
    let handleLink = () => {
      const routePath = props.item.path
      const target = props.item.meta.target
      if (target === '_blank') {
        if (isExternal(routePath)) window.open(routePath)
        else if (route.path !== routePath) window.open(routePath.href)
      } else {
        if (isExternal(routePath)) window.location.href = routePath
        else if (route.path !== routePath) router.push(routePath)
      }
    }

    return {
      hasChild,
      collapsed,
      handleLink,
      router
    }
  },

}
</script>

<style scoped>
.collapsed {

}
.unCollapsed {
  opacity: 0;
}
</style>
