import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-344d0b88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "anticon" }
const _hoisted_2 = { class: "anticon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_sidebar_item = _resolveComponent("sidebar-item")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!

  return ($props.item.meta && !$props.item.meta?.hidden)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (!$setup.hasChild())
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createCommentVNode("<sidebar-item-link :to=\"item.path\">"),
              (_openBlock(), _createBlock(_component_a_menu_item, {
                key: $props.item.path,
                onClickCapture: $setup.handleLink
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, [
                    _createVNode(_component_svg_icon, {
                      icon: $props.item.meta.icon
                    }, null, 8 /* PROPS */, ["icon"])
                  ]),
                  _createElementVNode("span", null, _toDisplayString($props.item.meta.title), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onClickCapture"])),
              _createCommentVNode("</sidebar-item-link>")
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createBlock(_component_a_sub_menu, {
              key: $props.item.path
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, [
                  _createVNode(_component_svg_icon, {
                    icon: $props.item.meta.icon
                  }, null, 8 /* PROPS */, ["icon"])
                ]),
                _createElementVNode("span", null, _toDisplayString($props.item.meta.title), 1 /* TEXT */)
              ]),
              default: _withCtx(() => [
                ($props.item.children)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.item.children, (child) => {
                      return (_openBlock(), _createBlock(_component_sidebar_item, {
                        key: child.path,
                        item: child
                      }, null, 8 /* PROPS */, ["item"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }))
      ], 64 /* STABLE_FRAGMENT */))
    : _createCommentVNode("v-if", true)
}