import { RouteRecordRaw } from "vue-router"
import Dashboard from "@/views/dashboard/index.vue"
import {shallowRef} from "vue";

export const systemRoutes: Array<RouteRecordRaw> = [
    {
        path: '/aliImg',
        component: shallowRef(Dashboard),
        meta: { title: "图片同步", icon: 'app', permissions: ['menu:platform:center'] },
        children: [
            {
                path: '/aliImg/product',
                name: 'product',
                meta: { title: '图片搜索', icon: 'app_list', permissions: ['menu:application:center'] },
                component: () => import("@/views/system/application/index.vue")
            },
            {
                path: '/aliImg/log',
                name: 'log',
                meta: { title: '上传日志', icon: 'app_list', permissions: ['menu:application:center'], hidden: true, noCache: true},
                component: () => import("@/views/system/application/log.vue")
            },
            {
                path: '/aliImg/logs',
                name: 'logs',
                meta: { title: '同步日志', icon: 'app_list', permissions: ['menu:application:center'], noCache: true},
                component: () => import("@/views/system/logs/index.vue")
            }
        ]
    }
]

