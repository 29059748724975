// @ts-ignore
import { DirectiveBinding, VNode, ObjectDirective } from "vue";
import { UserModule } from "@/store/module/user"

export const pms: ObjectDirective = {
    mounted(el: HTMLElement, binding: DirectiveBinding, vNode: VNode) {
        const { value } = binding
        const all_permission = "*.*.*"
        const myPermissions = UserModule.permissions

        if (value && value instanceof Array && value.length > 0) {
            const permissionFlag = value

            const hasPermission = myPermissions.some(permission => {
                return all_permission === permission || permissionFlag.includes(permission)
            })

            if (!hasPermission) {
                el.parentNode && el.parentNode.removeChild(el)
            }
        } else {
            throw new Error('请设置操作权限标签值')
        }
    }
}
