import RequireContext = __WebpackModuleApi.RequireContext

const req = require.context('./svg', true, /\.svg$/)
const requireAll = (requireContext: RequireContext) => {
    return requireContext.keys().map(requireContext)
}
requireAll(req)


const re = /\/(.*)\.svg/
const requireKeys = (requireContext: RequireContext) => {
    return requireContext.keys()
}
const icons = requireKeys(req).map((str: string) => {
    if (str.indexOf('/') > 0) {
        str = str.substring(str.lastIndexOf('/'))
    }
    return str.match(re)![1]
}).sort()
export default icons
