import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { RouteRecordRaw } from "vue-router"
import { constantRoutes, asyncRoutes} from "@/router"

export interface IPermissionState {
    routes: RouteRecordRaw[],
    dynamicRoutes: RouteRecordRaw[]
}

@Module({ dynamic: true, name: 'permission', store})
class Permission extends VuexModule implements IPermissionState {
    public routes: RouteRecordRaw[] = []
    public dynamicRoutes: RouteRecordRaw[] = []

    @Mutation
    private SET_ROUTES(routes: RouteRecordRaw[]) {
        this.routes = constantRoutes.concat(routes)
        this.dynamicRoutes = routes
    }

    @Action
    public GenerateRoutes() {
        this.SET_ROUTES(asyncRoutes)
    }
}

export const PermissionModule = getModule(Permission)
