import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc995e54"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_outlined = _resolveComponent("home-outlined")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!

  return (_openBlock(), _createBlock(_component_a_breadcrumb, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_breadcrumb_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_home_outlined, {
            class: "home",
            onClick: _ctx.toHome
          }, null, 8 /* PROPS */, ["onClick"])
        ]),
        _: 1 /* STABLE */
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item, index) => {
        return (_openBlock(), _createBlock(_component_a_breadcrumb_item, {
          key: item.path
        }, {
          default: _withCtx(() => [
            _createElementVNode("a", {
              onClick: _withModifiers(($event: any) => (_ctx.handleLink(item)), ["prevent"])
            }, _toDisplayString(item.meta.title), 9 /* TEXT, PROPS */, _hoisted_1)
          ]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}