import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46eefe02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "anticon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_sidebar_item = _resolveComponent("sidebar-item")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_menu, {
      mode: "inline",
      "inline-collapsed": _ctx.collapsed,
      theme: _ctx.theme,
      selectedKeys: _ctx.selectKeys,
      "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectKeys) = $event)),
      openKeys: _ctx.openKeys,
      "onUpdate:openKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.openKeys) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_menu_item, {
          key: "/dashboard",
          onClick: _ctx.toDashboard
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, {
              class: _normalizeClass(_ctx.light ? 'top-content' : 'top-content-black')
            }, {
              default: _withCtx(() => [
                (!_ctx.collapsed)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, " Dashboard "))
                  : (_openBlock(), _createElementBlock("span", _hoisted_2, [
                      _createVNode(_component_svg_icon, { icon: "dashboard" })
                    ]))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["class"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route) => {
          return (_openBlock(), _createBlock(_component_sidebar_item, {
            key: route.path,
            item: route
          }, null, 8 /* PROPS */, ["item"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["inline-collapsed", "theme", "selectedKeys", "openKeys"])
  ]))
}