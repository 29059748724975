import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a745b7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "screenfull" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullscreenExitOutlined = _resolveComponent("FullscreenExitOutlined")!
  const _component_FullscreenOutlined = _resolveComponent("FullscreenOutlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isFullscreen)
      ? (_openBlock(), _createBlock(_component_FullscreenExitOutlined, {
          key: 0,
          class: "navbar-icon",
          onClick: _ctx.click
        }, null, 8 /* PROPS */, ["onClick"]))
      : (_openBlock(), _createBlock(_component_FullscreenOutlined, {
          key: 1,
          class: "navbar-icon",
          onClick: _ctx.click
        }, null, 8 /* PROPS */, ["onClick"]))
  ]))
}