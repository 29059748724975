import requests from '@/utils/request'
import {AES_CBC_Decrypt, AES_CBC_Encrypt, RSA_Decrypt, RSA_Encrypt} from '@/utils/crypt';

export const getUserInfo = () =>
    requests({
        url: '/aliImg/userinfo',
        method: 'get',
    });

export const doLogout = () =>
    requests({
        url: '/aliImg/logout',
        method: 'get',
    });

export const refresh = (params: any) =>
    requests({
        url: '/auth/refresh',
        method: 'post',
        params,
    });

export const queryUsers = (params: any) =>
    requests({
        url: '/api/sys/user/list',
        method: 'get',
        params
    });

export const updateUser = (params: any) =>
    requests({
        url: '/api/sys/user/edit',
        method: 'post',
        data: params,
    });

export const enableUser = (userId: number) =>
    requests({
        url: '/api/sys/user/state/' + userId,
        method: 'delete'
    });

export const addUser = (params: any) =>
    requests({
        url: '/api/sys/user',
        method: 'post',
        data: params
    });

export const updatePassword = (params: any) =>
    requests({
        url: '/api/sys/user/password',
        method: 'put',
        params,
    });

export const deleteUser = (userId: any) =>
    requests({
        url: '/api/sys/user/' + userId,
        method: 'delete'
    })

export const resetGoogle = (userId: any) =>
    requests({
        url: '/api/sys/user/google/' + userId,
        method: 'put'
    })