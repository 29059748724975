import axios, {AxiosRequestConfig, AxiosResponse, Method} from 'axios'
import { message } from "ant-design-vue"
import { UserModule} from "@/store/module/user"
import router from "@/router"
import { getSign, urlEncode} from "@/utils/sign";
import { hasAccessToken } from '@/utils/cookies'
import { AES_CBC_Encrypt } from "@/utils/crypt";
import * as Url from "url";

const service = axios.create({
    headers: { 'content-type': 'application/json; charset=utf-8' },
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 500 * 1000,
    withCredentials: true
})

service.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        if (hasAccessToken()) {
            config.headers.Authorization = `${UserModule.access_token}`
        }
        config = signConfig(config)
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

service.interceptors.response.use(
    (response: AxiosResponse) => {
        const res: any = response.data
        // token不合法  用户不存在
        if (res.code === 95 || res.code === 96) {
            message.error(res.msg, 5 )
            router.replace('/login')
            return Promise.reject(res)
        } else if (res.code !== 0) {
            message.error(res.msg || 'Error', 5)
            return Promise.resolve(res)
        }
        return Promise.resolve(res)
    },
    (error) => {
        message.error(error.message, 5)
        return Promise.reject(error)
    }
)


function signConfig(config: AxiosRequestConfig): AxiosRequestConfig {
    if (process.env.VUE_APP_SERVER_SIGN === 'true') {
        let sign;
        const params = config.params === undefined ? {} : config.params
        let data = config.data === undefined ? {} : config.data
        let url = config.url
        let urlParams = urlEncode(params, null, false);
        const isGet = config.method?.toUpperCase() === 'GET';
        const timeStamp: number = new Date().getTime()
        if (isGet) {
            sign = getSign((url as string), params, isGet);
            url = `${url}?sign=${sign}&timeStamp=${timeStamp}${urlParams}`
        } else {
            sign = getSign((url as string), data, isGet);
            data = {...data, sign, timeStamp}
            config.data = data
        }
        config.params = undefined
        config.url = url
    }
    /*const params = config.params === undefined ? {} : config.params
    const data = config.data === undefined ? {} : config.data
    const url = config.url
    const isGet = config.method?.toUpperCase() === 'GET';
    config.headers.appId = '022D39F51209ECB6DE304A32DA8A6444'
    config.headers.timestamp = new Date().getTime()
    config.headers.sign = getSign((url as string), isGet ? params : data, isGet)*/
    return config
}

export default service
