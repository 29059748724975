// @ts-ignore
import { DirectiveBinding, VNode, ObjectDirective } from "vue";
import { UserModule } from "@/store/module/user"

export const role: ObjectDirective = {
    mounted(el: HTMLElement, binding: DirectiveBinding, vNode: VNode) {
        const { value } = binding
        const super_admin = ["super", "admin"]
        const myRoles = UserModule.roles

        if (value && value instanceof Array && value.length > 0) {
            const roleFlag = value

            const hasRole = myRoles.some(role => {
                return super_admin.includes(role) || roleFlag.includes(role)
            })

            if (!hasRole) {
                el.parentNode && el.parentNode.removeChild(el)
            }
        } else {
            throw new Error('请设置操作权限标签值')
        }
    }
}
