import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62be66ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "navbar-container",
  id: "navbar-container"
}
const _hoisted_2 = { class: "navbar-right-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_fold_outlined = _resolveComponent("menu-fold-outlined")!
  const _component_menu_unfold_outlined = _resolveComponent("menu-unfold-outlined")!
  const _component_rocket_two_tone = _resolveComponent("rocket-two-tone")!
  const _component_rocket_outlined = _resolveComponent("rocket-outlined")!
  const _component_screenfull = _resolveComponent("screenfull")!
  const _component_bread_crumb = _resolveComponent("bread-crumb")!
  const _component_message = _resolveComponent("message")!
  const _component_user = _resolveComponent("user")!
  const _component_out = _resolveComponent("out")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.menuCollapsed)
      ? (_openBlock(), _createBlock(_component_menu_fold_outlined, {
          key: 0,
          class: "navbar-icon",
          onClick: _ctx.foldOrUnFold
        }, null, 8 /* PROPS */, ["onClick"]))
      : (_openBlock(), _createBlock(_component_menu_unfold_outlined, {
          key: 1,
          class: "navbar-icon",
          onClick: _ctx.foldOrUnFold
        }, null, 8 /* PROPS */, ["onClick"])),
    (_ctx.light)
      ? (_openBlock(), _createBlock(_component_rocket_two_tone, {
          key: 2,
          onClick: _ctx.lightOrUnLight,
          class: "navbar-icon"
        }, null, 8 /* PROPS */, ["onClick"]))
      : (_openBlock(), _createBlock(_component_rocket_outlined, {
          key: 3,
          onClick: _ctx.lightOrUnLight,
          class: "navbar-icon"
        }, null, 8 /* PROPS */, ["onClick"])),
    _createVNode(_component_screenfull, { class: "right-menu-item hover-effect" }),
    _createVNode(_component_bread_crumb, { class: "navbar-bread" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_message, { class: "navbar-right-item" }),
      _createVNode(_component_user, { class: "navbar-right-item" }),
      _createVNode(_component_out, { class: "navbar-right-item" })
    ])
  ]))
}