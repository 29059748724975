<template>
  <router-link :to="to">
    <slot />
  </router-link>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { isExternal} from "@/utils/validate"
import {Prop} from "vue-property-decorator"

export default {

  name: 'sidebar-item-link',

  props: {
    to: String
  },

  setup(props) {

  },
}
</script>

<style scoped>

</style>
