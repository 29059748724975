import { RouteRecordRaw } from "vue-router"
import Dashboard from "@/views/dashboard/index.vue"
import {shallowRef} from "vue";

export const systemPageRoutes: Array<RouteRecordRaw> = [
    {
        path: '/error_page',
        component: shallowRef(Dashboard),
        meta: { title: "错误页", icon: 'pages', permissions: ['menu:system:page'] },
        children: [
            {
                path: '/error_page/403',
                name: 'Page403',
                meta: { title: '403', onCache: true, icon: '403', permissions: ['menu:page:403'] },
                component: shallowRef(() => import("@/views/403.vue"))
            },
            {
                path: '/error_page/404',
                name: 'Page404',
                meta: { title: '404', onCache: true, icon: '404', permissions: ['menu:page:404'] },
                component: shallowRef(() => import("@/views/404.vue"))
            }
        ]
    }
]
