<template>
  <div id="screenfull">
    <FullscreenExitOutlined class="navbar-icon" v-if="isFullscreen" @click="click"/>
    <FullscreenOutlined class="navbar-icon" v-else @click="click"/>
  </div>
</template>

<script lang="ts">
import screenfull from 'screenfull'
import {Options, Vue} from "vue-class-component";
import {message} from "ant-design-vue";
import { FullscreenExitOutlined, FullscreenOutlined} from "@ant-design/icons-vue";

const sf = screenfull

@Options({
  name: "screenfull",
  components: {
    FullscreenExitOutlined,
    FullscreenOutlined
  }
})
export default class Screenfull extends Vue {

  private isFullscreen = false

  mounted() {
    if (sf.isEnabled) {
      sf.on('change', this.change)
    }
  }

  beforeUnmount() {
    if (sf.isEnabled) {
      sf.off('change', this.change)
    }
  }

  private change() {
    if (sf.isEnabled) {
      this.isFullscreen = sf.isFullscreen
    }
  }

  private click() {
    if (!sf.isEnabled) {
      message.warn('当前浏览器不支持')
      return false
    }
    sf.toggle()
  }

}
</script>

<style lang="scss" scoped>
.navbar-icon {
  font-size: 25px;
  cursor: pointer;
  margin-right: 10px;
}
</style>
